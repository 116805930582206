import "./Newsblog.css";
import Modal from "react-bootstrap/Modal";
import {useState, useEffect, memo} from "react";
import {Link} from "react-router-dom";
import OfferImg5 from "../assets/PopUpJan.png";

export default function Newsblog() {
  const MyLink = memo((props) => {
    return <Link {...props} />;
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    setTimeout(() => setShow(true), 5000);
  }, []);

  function onLinkClick() {
    document.getElementById("offer").scrollIntoView({behavior:"smooth"});
    handleClose()
  }

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title >Aktuelle Kurse</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="currentOffer"
          >
            {/*<p>
              <strong>
                <h3>Yoga trifft Waldbaden in Hohenkammer</h3>
              </strong>
              <img id="imgWaldbaden" src={imgWaldbaden} alt="Waldbaden"/>
              <br></br>
              <br></br>
              Termin: 8.Oktober 2023
              <br></br>
              Zeit: 13.00 -15.30Uhr
              <br></br>
              Kosten: 59,00 Euro
              <br></br>
              Ort: 85411 Hohenkammer bei Freising
              <br></br>
              <br></br>
              Anmeldung:{" "}
              <a href="mailto:kontakt@sylkeheuer.de?subject=Anmeldung Waldbaden">kontakt@sylkeheuer.de </a>
              <br></br>
              <br></br>
              Weitere Infomationen findest du  <MyLink to={"/Yoga_in_der_Natur"} onClick={onLinkClick}>hier</MyLink>
              <br></br>
              <br></br>
              <br></br>*/}
              {/*<strong>
                <h3>Isaryoga</h3>
              </strong>
              <br></br>
              Deine Yogazeit in der Natur
              <br></br>
              <br></br>
              Mittwochs 18:00 - 19:00 Uhr
              <br></br>
              <br></br>
              Kommende Termine<br></br>
              11., 18., 25.September<br></br>
              <br></br>
              Probetermin 15,00 Euro
              <br></br>
              <br></br>
              Anmeldung bis 24 Stunden vorher:{" "}
              <a href="mailto:kontakt@sylkeheuer.de?subject=Anmeldung Isaryoga">kontakt@sylkeheuer.de </a>
              <br></br>
              <br></br>
              Weitere Infomationen findest du  <MyLink to={"/Yoga_in_der_Natur"} onClick={onLinkClick}>hier</MyLink>
              <br></br>
            </p>

             <p class="smallNote">Bei weniger als 3 Anmeldungen oder starkem Regen kann ein Termin abgesagt werden. Optional können wir in einen Raum ausweichen.</p>

          */}
          <img alt="flyer popup yoga" src={OfferImg5}
               style={{ maxWidth: '-webkit-fill-available', maxHeight:'100%'}}
          />
          <p>
            <br></br>
            Weitere Infomationen findest du  <MyLink to={"/Popup"} onClick={onLinkClick}>hier</MyLink>
            <br></br>
          </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
