import "../Newsblog.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useState } from "react";
import OfferImg5 from "../../assets/AboutFigure.jpeg";
import offerDescription from "../OfferDescription.jsx";

export default function YogaNatur() {
  const [matches, setMatches] = useState(window.innerWidth);
  window.addEventListener("resize", checkSize);

  function checkSize() {
    setMatches(window.innerWidth);
  }
  return (
    <>
      <section className="offerSec">
        <h4 className="introHeader">{offerDescription[0].title}</h4>
        <div className="intro">{offerDescription[0].body}</div>
        <h2 id="offer">Angebot</h2>

          <Row lg={2} sm={12} className="g-4">
            <Col>
              <Card className="cardOfferHeight">
                <Card.Body>
                  <Card.Title>Yoga in der Natur</Card.Title>
                  <br></br>
                  <Card.Text>
                    Vögelzwitschern, Dein Blick in die schöne ursprüngliche Natur, ins Grüne, aufs Wasser.
                    <br></br>
                    Die Luft spüren, Dich dehnen, bewegen. Energie aufnehmen, Ballast loslassen, regenerieren.
                  </Card.Text>
                  <br></br>
                  <Card.Subtitle class="subtitle">
                    Isaryoga
                  </Card.Subtitle>
                  <br></br>
                  <Card.Text>
                    Deine Yogazeit in der Natur.
                    <br></br>
                    <br></br>
                    Yoga in den Isarauen Ismaning. Über Deinen Atem, bewußtes Gehen und Yoga verbindest Du Dich mit Deinem Körper und der Natur und regst Deine Sinne an. Auftanken und wohlfühlen <strong>in kleiner Gruppe</strong>.
                    <br></br>
                    Du benötigst bequeme, wetterangepasste Kleidung und eine Matte.
                    <br></br>
                    <br></br>
                    Mittwochs 18:00 - 19:00 Uhr
                    <br></br>
                    <br></br>
                    Neue Termine ab 30.April
                  {/*  <br></br>
                    <br></br>
                    11., 18., 25.September<br></br>
                    <br></br>
                    Ab 9.Oktober optional indoor.<br></br>
                    <br></br>
                    1 Termin 	23,00 Euro<br></br>
                    3 Termine 	60,00 Euro<br></br>
                    5 Termine 	90,00 Euro<br></br>
                    <br></br>
                    Probetermin 15,00 Euro<br></br>
                    <br></br>
                    Unseren Treffpunkt erhältst Du nach Deiner Anmeldung.
                    <br></br>
                    Anmeldung bis 24 Stunden vorher:{" "}
                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anmeldung Isaryoga">kontakt@sylkeheuer.de </a>
                    <br></br>
                    <br></br>
                    <p class="smallNote">Bei weniger als 3 Anmeldungen oder starkem Regen kann ein Termin abgesagt werden. Optional können wir in einen Raum ausweichen.</p>
                    <br></br>
                    Du möchtest mit eine:r Freund:in zusammen Yoga in der Natur erleben?
                    <br></br>
                    Bitte kontaktiere mich und wir besprechen die Möglichkeiten.
                    <br></br>
                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage Yoga in der Natur">kontakt@sylkeheuer.de</a>*/}
                  </Card.Text>

                  {/*<br></br>
                  <Card.Subtitle class="subtitle">
                    Yoga trifft Waldbaden in Hohenkammer
                  </Card.Subtitle>
                  <br></br>
                  <Card.Text>
                    Der Wald als regenerativer, ruhiger und dabei erfrischender Ort um Energie aufzutanken ist auch ideal um Yoga zu praktizieren. Mit jedem Atemzug im Wald tust Du Deinem Körper Gutes. Nach nur 30 Minuten Waldbaden profitierst Du ungemein für Deine Gesundheit und Dein Wohlbefinden.
                    <br></br>
                    Erfahre Dich und die Natur, schlendernd und langsam genießen wir mit geöffneten Sinnen den Wald. Achtsame Wahrnehmungs- und Yogaübungen lassen uns im Hier & Jetzt verwurzeln und stärken unsere Standhaftigkeit.
                    <br></br>
                    <br></br>
                    Die Termine finden auch bei leichtem Regen statt, im Wald sind wir geschützt. Geeignet für alle, auch ohne Vorkenntnisse.
                    <br></br>
                    Claudia Müller ist zertifizierte Waldführerin, Sylke Heuer ausgebildete Yogalehrerin und bieten den Workshop gemeinsam an.
                    <br></br>
                    Zusammen mit ihrem Mann hat  Claudia einen Wald in Hohenkammer gepachtet, Dort findest Du dort unterschiedliche Veranstaltungen rund um das Thema Wald.
                    <br></br>
                    <a href="https://www.waldbaden-bayern.com" rel="noreferrer" target="_blank">waldbaden-bayern.com</a>
                    <br></br>
                    <br></br>
                    Wir freuen uns mit Dir gemeinsam die Energie des Waldes in Verbindung mit Yoga zu erfahren.
                    <br></br>
                    <br></br>
                    Termin: 8.Oktober 2023
                    <br></br>
                    Zeit: 13.00 -15.30Uhr
                    <br></br>
                    Kosten: 59,00 Euro
                    <br></br>
                    Ort: 85411 Hohenkammer bei Freising
                    <br></br>
                    <br></br>
                    Anmeldung:{" "}
                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anmeldung Waldbaden">kontakt@sylkeheuer.de </a>
                    <br></br>
                    <br></br>
                  </Card.Text>*/}
                </Card.Body>
              </Card>
              <LinkContainer to={`/`}>
                <Button>Zurück</Button>
              </LinkContainer>
            </Col>
            {matches > 768 && (
            <Col>
              <Card.Img className="cardImageDetail" variant="top" src={OfferImg5} />
            </Col>
            )}
          </Row>
      </section>
    </>
  );
}
